import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import debounce from "lodash.debounce"
import { StoreContext } from "../context/store-context"
import { formatPrice } from "../utils/format-price"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { NumericInput } from "./numeric-input"
import { useIntl } from "gatsby-plugin-react-intl"
import { css } from "@emotion/react"
import { device } from "../styles/device"

export function LineItem({ item }) {
    const intl = useIntl()

    const data = useStaticQuery(graphql`
        query {
            allContentfulShopSection {
                nodes {
                    productImage {
                        gatsbyImageData
                    }
                }
            }
        }
    `)
    const content =
        intl.locale === "ja" ? data.allContentfulShopSection.nodes[0] : data.allContentfulShopSection.nodes[1]

    const { removeLineItem, checkout, updateLineItem, loading } = React.useContext(StoreContext)
    const [quantity, setQuantity] = React.useState(item.quantity)

    const price = formatPrice(item.variant.priceV2.currencyCode, Number(item.variant.priceV2.amount))

    const subtotal = formatPrice(item.variant.priceV2.currencyCode, Number(item.variant.priceV2.amount) * quantity)

    const handleRemove = () => {
        removeLineItem(checkout.id, item.id)
    }

    const uli = debounce((value) => updateLineItem(checkout.id, item.id, value), 300)
    // eslint-disable-next-line
    const debouncedUli = React.useCallback((value) => uli(value), [])

    const handleQuantityChange = (value) => {
        if (value !== "" && Number(value) < 1) {
            return
        }
        setQuantity(value)
        if (Number(value) >= 1) {
            debouncedUli(value)
        }
    }

    function doIncrement() {
        handleQuantityChange(Number(quantity || 0) + 1)
    }

    function doDecrement() {
        handleQuantityChange(Number(quantity || 0) - 1)
    }

    return (
        <tr css={bodyRowStyle}>
            <td>
                <GatsbyImage
                    loading="eager"
                    alt={`Cart Image`}
                    image={getImage(content.productImage[0])}
                    css={productImage}
                    objectFit="contain"
                />
            </td>
            <td>
                <p>{item.title}</p>
                <p>Size: {item.variant.title === "Default Title" ? "" : item.variant.title}</p>
            </td>
            <td>{price}</td>
            <td>
                <NumericInput
                    disabled={loading}
                    value={quantity}
                    aria-label="Quantity"
                    onIncrement={doIncrement}
                    onDecrement={doDecrement}
                    onChange={(e) => handleQuantityChange(e.currentTarget.value)}
                />
            </td>
            <td>{subtotal}</td>
            <td css={removeTd}>
                <button type="button" onClick={handleRemove} css={removeButton}>
                    {intl.formatMessage({ id: "remove" })}
                </button>
            </td>
        </tr>
    )
}

const bodyRowStyle = css`
    td {
        padding: 20px 5% 20px 0;
    }
    td:nth-of-type(1) {
        width: 25%;
    }
    td:nth-of-type(2) {
        width: 20%;
    }
    td:nth-of-type(3) {
        width: 15%;
    }
    td:nth-of-type(4) {
        width: 20%;
    }
    td:nth-of-type(5) {
        width: 15%;
    }
    td:nth-of-type(6) {
        width: auto;
        padding-right: 0;
    }
    @media ${device.tb} {
        display: block;
        width: 100%;
        border-bottom: solid 1px black;
        td {
            display: block;
            width: 100% !important;
            padding: 10px 0;
        }
        /* td:nth-of-type(1) {
            text-align: center;
        } */
    }
`
const removeTd = css`
    @media ${device.tb} {
        text-align: right;
        padding: 0 0 5px !important;
    }
`
const removeButton = css`
    background: white;
    border: none;
    letter-spacing: 0.1rem;
    opacity: 0.5;
    cursor: pointer;
`
const productImage = css`
    width: min(100%, 300px);
    height: min(100%, 300px);

    @media ${device.sp} {
        display: block;
        margin: 0 auto;
    }
`
