import * as React from "react"
import { Layout } from "../components/layout"
import { StoreContext } from "../context/store-context"
import { LineItem } from "../components/line-item"
import { formatPrice } from "../utils/format-price"
import ShopSection from "../components/shopSection"
import ContactUsSection from "../components/contactUsSection"
import { useIntl } from "gatsby-plugin-react-intl"
import { css } from "@emotion/react"
import { device } from "../styles/device"

export default function CartPage() {
    const intl = useIntl()

    const { checkout, loading } = React.useContext(StoreContext)
    const emptyCart = checkout.lineItems.length === 0

    const handleCheckout = () => {
        // FIX THIS: not working due to shopify api update
        const checkoutJA = `${checkout.webUrl}?locale=ja`
        const checkoutEN = `${checkout.webUrl}?locale=en`

        //switch checkout language based on locale
        const checkoutUrl = intl.locale === "ja" ? checkoutJA : checkoutEN
        console.log(checkout)
        window.open(checkoutUrl)
    }

    return (
        <Layout>
            {emptyCart ? (
                <>
                    <div css={wrapper}>
                        <div css={innerWrapper}>
                            <h3>{intl.formatMessage({ id: "is_empty" })}</h3>
                        </div>
                    </div>
                    <ShopSection />
                </>
            ) : (
                <div css={wrapper}>
                    <h4 css={cartTitle}>Cart</h4>
                    <table css={cartTable}>
                        <thead>
                            <tr css={topRowStyle}>
                                <th>Product</th>
                                <th></th>
                                <th>Price</th>
                                <th>Qty.</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody css={tbodyStyle}>
                            {checkout.lineItems.map((item) => (
                                <LineItem item={item} key={item.id} />
                            ))}
                            <tr css={firstBottomRowStyle}>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {/* <td>{intl.formatMessage({ id: "tax" })}</td>
                                <td>{formatPrice(checkout.totalTaxV2.currencyCode, checkout.totalTaxV2.amount)}</td> */}
                            </tr>
                            <tr css={bottomRowStyle}>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{intl.formatMessage({ id: "total_price" })}</td>
                                <td>{formatPrice(checkout.totalPriceV2.currencyCode, checkout.totalPriceV2.amount)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div css={bottomContainer}>
                        <button
                            type="button"
                            aria-label="cart button"
                            onClick={handleCheckout}
                            disabled={loading}
                            css={checkoutButton}
                        >
                            {intl.formatMessage({ id: "shipment" })}
                        </button>
                        <p css={shippingCost}>{intl.formatMessage({ id: "shipping_calc" })}</p>
                    </div>
                </div>
            )}
            <ContactUsSection />
        </Layout>
    )
}

const wrapper = css`
    margin: 0 auto 20vh;
    padding-top: 10vh;
    width: min(80vw, 2000px);
    @media ${device.tb} {
        padding-top: 2vh;
        margin: 0 auto 10vmax;
    }
`
const innerWrapper = css`
    text-align: center;
    margin-bottom: 20vh;
    @media ${device.tb} {
        margin-bottom: 10vmax;
    }
`
const cartTable = css`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1.6rem;
    @media ${device.tb} {
        display: block;
        width: 100%;
    }
`
const tbodyStyle = css`
    @media ${device.tb} {
        display: block;
        width: 100%;
        border-top: solid 1px black;
        border-bottom: solid 1px black;
    }
`
const topRowStyle = css`
    border-bottom: solid 1px black;
    th {
        padding-bottom: 12px;
        opacity: 0.5;

        @media ${device.tb} {
            display: none;
        }
    }
`
const cartTitle = css`
    text-align: center;
    margin-bottom: 5rem;
`
const bottomRowStyle = css`
    td {
        padding: 1.2rem 0;
    }
    @media ${device.tb} {
        display: block;
        width: 100%;

        td:nth-last-of-type(1) {
            padding-left: 1rem;
        }
    }
`
const firstBottomRowStyle = css`
    ${bottomRowStyle}
    border-top: solid 1px black;
    @media ${device.tb} {
        border-top: none;
    }
`
const shippingCost = css`
    font-size: 1.2rem;
    margin-top: 3rem;
    line-height: 1.6rem;
`
const bottomContainer = css`
    text-align: right;
    margin-top: 4rem;
`
const checkoutButton = css`
    background: black;
    color: white;
    border: solid 1px black;
    width: 20vw;
    height: 60px;
    cursor: pointer;
    border-radius: 2px;
    &:hover {
        background: transparent;
        color: black;
        border: solid 1px black;
    }

    @media ${device.tb} {
        width: 100%;
    }

    @media ${device.sp} {
        font-size: 1.55rem;
    }
`
